@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');
@font-face {
  font-family: 'HeyAugust';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./fonts/HeyAugust.woff2) format('woff');
}


:root {
  --blue: #002B3D;
  --orange: #FF8C00;
  --lightBlue: #7FD7EA;
  --darkGray: #333333;
  --lightGray: #F2F2F2;
}

body {
  background: url(./images/bg.jpg) top center no-repeat, var(--blue);
  background-size: 100%;
  font-family: 'Inter', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'HeyAugust';
  text-shadow: 0 4px 4px rgba(0,0,0,0.25);
  font-weight: 300;

  em {
    color: var(--orange);
    font-style: normal;
  }

  strong {
    color: var(--lightBlue);
  }
}

.logo {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
}

.App {
  text-align: center;
}

.hero {
  background: url(./images/hero-bg2.jpg) top center no-repeat;
  background-size: cover;
}

.transformation {
  background-image: url(./images/spine.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 20px;
}

.corner-frame-bl {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 0.75rem;
    background-color: var(--lightBlue);
    width: 50%;
    height: 50%;
    bottom: -10px;
    left: -10px;
    z-index: 0;
  }
}

.corner-frame-br {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 0.75rem;
    background-color: var(--lightBlue);
    width: 50%;
    height: 50%;
    bottom: -10px;
    right: -10px;
    z-index: 0;
  }
}

.corner-frame-tl {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 0.75rem;
    background-color: var(--lightBlue);
    width: 50%;
    height: 50%;
    top: -10px;
    left: -10px;
    z-index: 0;
  }
}

.corner-frame-tr {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 0.75rem;
    background-color: var(--lightBlue);
    width: 50%;
    height: 50%;
    top: -10px;
    right: -10px;
    z-index: 0;
  }
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  
  border-top: 12px solid #fff;
}

.markdown p {
  margin-bottom: 1rem;
}

ol {
  list-style: none;
  counter-reset: num;
}

ol li {
  margin-bottom: 1.5rem;
  counter-increment: num;
  clear: both;
}

ol li::before {
  display: block;
  width: 2rem;
  text-align: center;
  margin: -1rem 1rem 0 0;
  float: left;
  content: counter(num)".";
  font-family: 'HeyAugust';
  font-size: 48px;
}

.markdown h1,
.markdown h2,
.markdown h3 {
  margin-bottom: 1.5rem;
}

.markdown h1 {
  font-size: 3rem;
}

.markdown h2 {
  font-size: 2.5rem;
}

.markdown h3 {
  font-size: 2rem;
}